
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'SafeHtml',
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const sanitizedHtml = ref('');

    onMounted(async () => {
      const DOMPurify = await import('dompurify');
      sanitizedHtml.value = DOMPurify.sanitize(props.htmlContent);
    });

    return {
      sanitizedHtml,
    };
  },
});
